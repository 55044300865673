body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media print and (width: 210mm) and (height: 297mm) {
  body.a4-preview {
    width: 210mm;
    height: 297mm;
    margin: 0;
    padding: 20mm; /* Adjust as needed */
    font-size: 12pt; /* Adjust as needed */
  }

  /* Additional styles for other elements on the page */
}